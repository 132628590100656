/*
  Authors : The MESD Technology
  Website : https://mesdtech.com/
  App Name : MESD Delivery APP
  This App Template Source code is licensed as per the
  terms found in the Website https://mesdtech.com/license
  Copyright and Good Faith Purchasers © 2021-present THE MESD.
*/
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://crosdealapi.mesdtech.co.in/public/api/', // ex https://yourapi.com/public/api/ don't forgot to add public/api/ at the end
  imageUrl: 'https://crosdealapi.mesdtech.co.in/public/storage/images/', // ex https://yourapi.com/public/storage/images/ don't forgot to add public/storage/images at the end
};
